import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { OrdersState } from '@/store/modules/orders/types'
import client from 'axios'
import { apiEndpoints } from '@/shared/endpoints/api'
import { OrderMutations } from '@/store/modules/orders/mutations'
import { OrderModel } from '@/shared/models/order.model'
import { OrderDetailsModel } from '@/shared/models/order-details.model'
import { OrderStatusesEnum } from '@/shared/enums/order-statuses.enum'
import { CacheSettlementModel } from '@/shared/models/cache-settlement.model'
import { ReturnedOrderModel } from '@/shared/models/returned-order.model'
import { ReturnedOrderDetailsModel } from '@/shared/models/returned-order-details.model'
import { useToast } from 'vue-toastification'
import { NearbyOrderModel } from '@/shared/models/nearby-order.model'
import moment from 'moment'
import axiosInstance from '@/shared/utils/axiosInstance'
import { Order } from '@/shared/interfaces/order.interface'
import { OrderAutocompleteModel } from '@/shared/models/order-autocomplete.model'

export enum OrderActions {
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_ORDERS_AUTOCOMPLETE = 'FETCH_ORDERS_AUTOCOMPLETE',
  FETCH_ORDERS_STATUSES = 'FETCH_ORDERS_STATUSES',
  FETCH_ORDER_BY_ID = 'FETCH_ORDER_BY_ID',
  FETCH_ORDERS_HISTORY = 'FETCH_ORDERS_HISTORY',
  FETCH_CASH_SETTLEMENTS = 'FETCH_CASH_SETTLEMENTS',
  ASSIGN_ORDER = 'ASSIGN_ORDER',
  MANUEL_ORDER_ASSIGN = 'MANUEL_ORDER_ASSIGN',
  MANUEL_ORDER_UN_ASSIGN = 'MANUEL_ORDER_UN_ASSIGN',
  CANCEL_ORDER = 'CANCEL_ORDER',
  GET_RETURNED_ITEMS = 'GET_RETURNED_ITEMS',
  GET_RETURNED_ITEM = 'GET_RETURNED_ITEM',
  FETCH_ORDER_STATUSES = 'FETCH_ORDER_STATUSES',
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPDATE_VEHICLE_TYPE = 'UPDATE_VEHICLE_TYPE',
  FETCH_NEARBY_ORDERS = 'FETCH_NEARBY_ORDERS',
  UNASSIGN_ORDER = 'UNASSIGN_ORDER',
  FETCH_ACCEPTED_ORDERS = 'FETCH_ACCEPTED_ORDERS',
  FETCH_ASSIGNED_DRIVER_ORDERS = 'FETCH_ASSIGNED_DRIVER_ORDERS',
  FETCH_DARKSTORE_ORDERS = 'FETCH_DARKSTORE_ORDERS',
  TOGGLE_ORDER_MANUAL = 'TOGGLE_ORDER_MANUAL'
}

export const actions: ActionTree<OrdersState, RootState> = {
  [OrderActions.FETCH_ORDERS]: async (context, payload) => {
    const axiosSource = client.CancelToken.source()
    if (context.state.request) {
      context.state.request.cancel()
    }
    context.commit('storeRequest', axiosSource)
    context.commit(OrderMutations.LOADING_EVENT, true)
    const params = new URLSearchParams()
    params.append('per_page', payload.pageSize)
    params.append('page_number', payload.pageNumber)
    if (payload.priority && payload.priority !== 'all') {
      params.append('is_prime', payload.priority === 'prime' ? '1' : '0')
    }
    if (payload.orderStatus) {
      if (payload.orderStatus === 'prime') {
        params.append('is_prime', '1')
      }
      if (OrderStatusesEnum[payload.orderStatus]) {
        if (OrderStatusesEnum[payload.orderStatus] === 'not_assigned') {
          params.append('no_driver', '1')
          params.append('order_status_ids', '1')
          params.append('order_status_ids', '2')
        } else {
          params.append('order_status_ids', OrderStatusesEnum[payload.orderStatus])
        }
      }
    }
    if (payload.createdAt_from) {
      params.append('from_date', moment(payload.createdAt_from).format('YYYY-MM-DD hh:mm:ss'))
      params.append('to_date', moment(payload.createdAt_to).format('YYYY-MM-DD hh:mm:ss'))
    } else {
      params.append('from_date', moment().add(-24, 'h').format('YYYY-MM-DD HH:mm:ss'))
      params.append('to_date', moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss'))
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    if (payload.currentAssigned) {
      params.append('current_assigned', payload.currentAssigned)
    }
    if (payload.isDelayed) {
      params.append('is_delayed', payload.isDelayed)
    }
    if (payload.darkstoreIds && payload.darkstoreIds.split(':')[1] !== '') {
      payload.darkstoreIds.split(':')[1].split(',').forEach((el: any) => {
        params.append('dark_store_ids', el)
      })
    } else {
      delete payload.darkstoreIds
    }
    if (payload.orderBy && payload.sort) {
      params.append('order_by', `${payload.orderBy}_${payload.sort === 'asc' ? 'asc' : 'desc'}`)
    }
    if (payload.assigmentType && ['manual', 'auto'].includes(payload.assigmentType)) {
      const assigmentTypeVal = payload.assigmentType === 'manual' ? 1 : 0
      params.append('is_manual', assigmentTypeVal.toString())
    }
    if (payload.driverId) {
      Object.assign(params, { driver_ids: payload.driverId })
    }
    await axiosInstance({
      method: apiEndpoints.order.list.method,
      url: apiEndpoints.order.list.url,
      params,
      cancelToken: axiosSource.token
    }).then(resp => {
      context.commit(OrderMutations.LOADING_EVENT, false)
      const orderModel = new OrderModel(resp.data)
      const data = {
        data: orderModel.data,
        paginationData: orderModel.paginationData,
        reset: payload.reset
      }
      context.commit(OrderMutations.SET_ORDERS, data)
    }).catch(err => {
      context.commit(OrderMutations.LOADING_EVENT, false)
      if (!client.isCancel(err)) {
        throw new Error(err)
      }
    })
  },
  [OrderActions.FETCH_ORDERS_AUTOCOMPLETE]: async (context, payload) => {
    context.commit(OrderMutations.LOADING_EVENT, true)
    const params = new URLSearchParams()
    params.append('per_page', payload.pageSize)
    params.append('page_number', payload.pageNumber)
    if (payload.search && payload.search !== '') {
      params.append('query', payload.search)
    }
    if (payload.driverId) {
      params.append('driver_id', payload.driverId)
    }
    await axiosInstance({
      method: apiEndpoints.order.listAutocomplete.method,
      url: apiEndpoints.order.listAutocomplete.url,
      params
    }).then(resp => {
      context.commit(OrderMutations.LOADING_EVENT, false)
      const orderModel = new OrderAutocompleteModel(resp.data)
      const data = {
        data: orderModel.data,
        paginationData: orderModel.paginationData
      }
      context.commit(OrderMutations.SET_ORDERS_AUTOCOMPLETE, data)
    }).catch(err => {
      context.commit(OrderMutations.LOADING_EVENT, false)
      if (!client.isCancel(err)) {
        throw new Error(err)
      }
    })
  },

  [OrderActions.FETCH_ORDERS_STATUSES]: async (context, payload) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (!payload.ordersIds.length) return
      const params = new URLSearchParams()
      payload.ordersIds.forEach((el: any) => {
        params.append('order_ids', el)
      })
      await axiosInstance({
        method: apiEndpoints.order.orderLastStatus.method,
        url: apiEndpoints.order.orderLastStatus.url,
        params: params
      }).then(resp => {
        if (resp?.data?.data) {
          let newList: Order[] = []
          if (payload.orderType === 'all' || payload.orderType === 'prime') {
            newList = context.state.orderList.map((order: Order) => {
              resp.data.data.forEach((itm: any) => {
                if (order.orderId === itm.order_id) {
                  order.orderStatus = {
                    id: itm.order_status.id,
                    name: itm.order_status.name,
                    value: itm.order_status.value
                  }
                }
                if (context.state.detailedOrder && context.state.detailedOrder.orderId === itm.order_id) {
                  context.state.detailedOrder.orderStatus = {
                    id: itm.order_status.id,
                    name: itm.order_status.name,
                    value: itm.order_status.value
                  }
                }
              })
              return order
            })
          } else if (payload.orderType !== 'delivered' && payload.orderType !== 'canceled') {
            newList = context.state.orderList.filter((order: Order) => {
              return resp.data.data.find((itm: any) => {
                if (context.state.detailedOrder && context.state.detailedOrder.orderId === itm.order_id) {
                  context.state.detailedOrder.orderStatus = {
                    id: itm.order_status.id,
                    name: itm.order_status.name,
                    value: itm.order_status.value
                  }
                }
                if (order.orderId === itm.order_id && order.orderStatus.id === itm.order_status.id) {
                  return order
                }
              })
            })
            if (!newList.length) {
              context.commit(OrderMutations.NAVIGATE_TO_NEXT_PAGE, true)
            }
            context.state.orderList = newList
          }
          resolve(resp)
        }
      }).catch(err => {
        throw new Error(err)
        reject(err)
      })
    })
  },
  [OrderActions.GET_RETURNED_ITEMS]: async (context, payload) => {
    context.commit(OrderMutations.LOADING_EVENT, true)
    const params = { per_page: payload.pageSize, page_number: payload.pageNumber }
    await axiosInstance({
      method: apiEndpoints.order.returnedItems.method,
      url: apiEndpoints.order.returnedItems.url,
      params
    }).then(resp => {
      context.commit(OrderMutations.LOADING_EVENT, false)
      const returnedOrders = new ReturnedOrderModel(resp.data)
      const data = {
        data: returnedOrders.data,
        paginationData: returnedOrders.paginationData,
        reset: payload.pageNumber === 1
      }
      context.commit(OrderMutations.SET_RETURNED_ORDERS, data)
    }).catch(err => {
      context.commit(OrderMutations.LOADING_EVENT, false)
      throw new Error(err)
    })
  },
  [OrderActions.FETCH_ORDER_BY_ID]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.order.byId.method,
      url: apiEndpoints.order.byId.url + payload.orderId
    }).then(resp => {
      const orderModel = new OrderDetailsModel(resp.data)
      context.commit(OrderMutations.SET_ORDER_DETAILS, orderModel.data)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.FETCH_NEARBY_ORDERS]: async (context, orderId) => {
    await axiosInstance({
      method: apiEndpoints.order.nearByOrders.method,
      url: apiEndpoints.order.nearByOrders.url + orderId
    }).then(resp => {
      const nearbyOrdersModel = new NearbyOrderModel(resp.data)
      context.commit(OrderMutations.SET_NEARBY_ORDERS, nearbyOrdersModel.data)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.GET_RETURNED_ITEM]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.order.returnedItembyId.method,
      url: apiEndpoints.order.returnedItembyId.url + payload.orderId
    }).then(resp => {
      const orderModel = new ReturnedOrderDetailsModel(resp.data)
      context.commit(OrderMutations.SET_RETURNED_ORDER_DETAILS, orderModel.data)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.FETCH_ORDERS_HISTORY]: async (context, payload) => {
    const params = { per_page: 8 }
    if (payload.pageNumber) {
      Object.assign(params, { page_number: payload.pageNumber })
    }
    if (payload.from_date) {
      Object.assign(params, { from_date: payload.from_date })
    }
    if (payload.to_date) {
      Object.assign(params, { to_date: payload.to_date })
    }
    if (payload.driverId) {
      Object.assign(params, { driver_ids: payload.driverId })
    }
    if (payload.search && payload.search !== '') {
      Object.assign(params, { search: payload.search })
    }
    await axiosInstance({
      method: apiEndpoints.order.list.method,
      url: apiEndpoints.order.list.url,
      params
    }).then(resp => {
      const orderModel = new OrderModel(resp.data)
      context.commit(OrderMutations.SET_ORDERS_HISTORIES, { orders: orderModel, refresh: payload.refresh })
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.FETCH_CASH_SETTLEMENTS]: async (context, payload) => {
    const params = { per_page: 10, page_number: 1 }
    if (payload.driverId) {
      Object.assign(params, { driver_ids: payload.driverId })
    }
    await axiosInstance({
      method: apiEndpoints.order.cacheSettlement.method,
      url: apiEndpoints.order.cacheSettlement.url,
      params
    }).then(resp => {
      const cacheSettlement = new CacheSettlementModel(resp.data)
      context.commit(OrderMutations.SET_ORDERS_HISTORIES, cacheSettlement.data)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.FETCH_ACCEPTED_ORDERS]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.order.acceptedOrder.method,
      url: apiEndpoints.order.acceptedOrder.url,
      params: { driver_id: payload }
    }).then(resp => {
      const count = resp.data.data.orders_count
      context.commit(OrderMutations.SET_ACCEPTED_ORDERS, count)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.ASSIGN_ORDER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      let url: string
      if (payload.data.taza_order_id) {
        url = apiEndpoints.order.assignMultipleOrder.url
      } else {
        url = apiEndpoints.order.assignOrder.url
      }
      axiosInstance({
        method: apiEndpoints.order.assignOrder.method,
        url: url + payload.orderId,
        data: payload.data
      }).then((res: any) => {
        const toast = useToast()
        toast.success('Driver assigned successfully', {
          timeout: 5000
        })
        resolve(res)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  },
  [OrderActions.MANUEL_ORDER_ASSIGN]: (context, { orderId, payload }) => {
    return new Promise((resolve, reject) => {
      const toast = useToast()
      axiosInstance({
        ...apiEndpoints.order.manuelOrderAssign(orderId),
        data: payload
      }).then((res: any) => {
        toast.success('Driver assigned successfully', {
          timeout: 5000
        })
        resolve(res)
      }).catch(err => {
        toast.error(err.response?.data?.info?.message, {
          timeout: 5000
        })
        reject(err)
        throw new Error(err)
      })
    })
  },
  [OrderActions.MANUEL_ORDER_UN_ASSIGN]: (context, { orderId, payload }) => {
    return new Promise((resolve, reject) => {
      const toast = useToast()
      axiosInstance({
        ...apiEndpoints.order.manuelOrderUnAssign(orderId),
        data: payload
      }).then((res: any) => {
        toast.success('Driver un-assigned successfully', {
          timeout: 5000
        })
        resolve(res)
      }).catch(err => {
        throw new Error(err)
        reject(err)
      })
    })
  },
  [OrderActions.UNASSIGN_ORDER]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.order.unassignOrder.method,
        url: apiEndpoints.order.unassignOrder.url + payload.orderId,
        data: payload.data
      }).then((res: any) => {
        const toast = useToast()
        toast.success('Order unassigned successfully', {
          timeout: 5000
        })
        resolve(res)
      }).catch(err => {
        reject(err)
        throw new Error(err)
      })
    })
  },
  [OrderActions.FETCH_ORDER_STATUSES]: async (context) => {
    await axiosInstance({
      method: apiEndpoints.order.statuses.method,
      url: apiEndpoints.order.statuses.url
    }).then(resp => {
      context.commit(OrderMutations.SET_ORDER_STATUSES, resp.data.data)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.CANCEL_ORDER]: async (context, payload) => {
    await axiosInstance({
      method: apiEndpoints.order.cancelOrder.method,
      url: apiEndpoints.order.cancelOrder.url + payload.orderId,
      data: {
        is_order_canceled: 1
      }
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.UPDATE_STATUS]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.order.updateStatus.method,
        url: apiEndpoints.order.updateStatus.url + payload.orderId,
        data: { order_status_id: payload.statusId }
      }).then((res: any) => {
        const toast = useToast()
        toast.success(res.data?.info?.message, {
          timeout: 3000
        })
        resolve(res)
      }).catch(err => {
        throw new Error(err)
        reject(err)
      })
    })
  },
  [OrderActions.UPDATE_VEHICLE_TYPE]: (context, payload) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: apiEndpoints.order.updateVehicleType.method,
        url: apiEndpoints.order.updateVehicleType.url + payload.orderId,
        data: { vehicle_type_id: payload.vehicleTypeId }
      }).then((res: any) => {
        const toast = useToast()
        resolve(res)
        toast.success(res.data?.info?.message, {
          timeout: 3000
        })
      }).catch(err => {
        throw new Error(err)
      })
    })
  },
  [OrderActions.FETCH_ASSIGNED_DRIVER_ORDERS]: async (context, payload) => {
    context.commit(OrderMutations.LOADING_EVENT, true)
    const params = { per_page: 10, page_number: payload.pageNumber, current_assigned: 1 }
    if (payload.driverId) {
      Object.assign(params, { driver_ids: payload.driverId })
    }
    await axiosInstance({
      ...apiEndpoints.order.list,
      params
    }).then(resp => {
      const orderModel = new OrderModel(resp.data)
      const data = {
        data: orderModel.data,
        paginationData: orderModel.paginationData,
        reset: true
      }
      context.commit(OrderMutations.LOADING_EVENT, false)
      context.commit(OrderMutations.SET_ORDERS, data)
    }).catch(err => {
      throw new Error(err)
    })
  },
  [OrderActions.FETCH_DARKSTORE_ORDERS]: (context, darkstoreId) => {
    const params = new URLSearchParams()
    params.append('dark_store_ids', darkstoreId)
    params.append('per_page', '10000')
    params.append('page', '1')
    return new Promise((resolve, reject) => {
      axiosInstance({
        ...apiEndpoints.order.list,
        params
      }).then((res: any) => {
        resolve(res)
      }).catch(err => {
        throw new Error(err)
      })
    })
  },
  [OrderActions.TOGGLE_ORDER_MANUAL]: (context, { orderId, reasonObject }) => {
    return new Promise((resolve, reject) => {
      const toast = useToast()
      axiosInstance({
        ...apiEndpoints.order.toggleManual(orderId),
        data: reasonObject
      }).then((res: any) => {
        toast.success('success', {
          timeout: 3000
        })
        resolve(res)
      }).catch(err => {
        toast.error(err.response?.data?.info?.message, {
          timeout: 3000
        })
        reject(err)
        throw new Error(err)
      })
    })
  }
}
