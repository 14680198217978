import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { UserRequestsInterface } from '@/shared/interfaces/user-requests.interface'
import { formatDate } from '@/shared/utils/formatDate'
import moment from 'moment'

export class UserRequestsModel extends CommonModel {
  public data: UserRequestsInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): UserRequestsInterface {
    return {
      id: data.id,
      createdAt: this.addGMT(data.created_at),
      clockInAt: formatDate(data.clock_in_at, 'DD.MM.YYYY HH:mm'),
      clockOutAt: formatDate(data.clock_out_at, 'DD.MM.YYYY HH:mm'),
      description: data.description,
      driver: {
        id: data.driver.id,
        driverInfo: {
          id: data.driver.driver_info.id,
          firstName: data.driver.driver_info.first_name,
          lastName: data.driver.driver_info.last_name,
          userName: data.driver.driver_info.username
        }
      },
      isClockOutApproved: data.is_clock_out_approved,
      isOvertimeApproved: data.is_overtime_approved,
      isOvertimeRequested: data.is_overtime_requested,
      isClockOutRequested: data.is_clock_out_requested,
      totalOvertimeHours: data.total_overtime_hours,
      totalWorkingHours: data.total_working_hours,
      totalOvertimeMinutes: this.timeConvert(data.total_overtime_minutes),
      totalWorkingMinutes: this.timeConvert(data.total_working_minutes)
    }
  }

  private timeConvert (n: number): any {
    const num = n
    const hours = (num / 60)
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    return [rhours, rminutes]
  }

  private addGMT (date: string): string {
    if (date.split('+').length > 1) {
      return moment(date.split('+')[0]).utc().local().format('DD.MM.YYYY hh:mm')
    } else {
      return moment(date).utc().local().format('DD.MM.YYYY hh:mm')
    }
    // if (date.split('+').length > 1) {
    //   return `${formatDate(date.split('+')[0], 'DD.MM.YYYY hh:mm')} GMT+${date.split('+')[1]}`
    // } else {
    //   return formatDate(date, 'DD.MM.YYYY hh:mm')
    // }
  }
}
