import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { apiEndpoints } from '@/shared/endpoints/api'
import { DarkstoreModel } from '@/shared/models/darkstore.model'
import { DarkStoresState } from '@/store/modules/darkstores/types'
import { DarkStoresMutations } from '@/store/modules/darkstores/mutations'
import axiosInstance from '@/shared/utils/axiosInstance'
import { useToast } from 'vue-toastification'
const toast = useToast()

export enum DarkStoresActions {
  FETCH_DARKSTORES = 'FETCH_DARKSTORES',
  UPDATE_DARKSTORES_SETTINGS = 'UPDATE_DARKSTORES_SETTINGS',
}

export const actions: ActionTree<DarkStoresState, RootState> = {
  [DarkStoresActions.FETCH_DARKSTORES]: async (context, payload) => {
    context.commit(DarkStoresMutations.TRIGGER_LOADING, true)
    const params = new URLSearchParams()
    if (payload.pageNumber) {
      params.append('page_number', payload.pageNumber)
    }
    if (payload.pageSize) {
      params.append('per_page', payload.pageSize)
    }
    if (payload.search && payload.search !== '') {
      params.append('search', payload.search)
    }
    await axiosInstance({
      method: apiEndpoints.darkstores.list.method,
      url: apiEndpoints.darkstores.list.url,
      params
    }).then((resp: any) => {
      context.commit(DarkStoresMutations.TRIGGER_LOADING, false)
      const darkStoreModel = new DarkstoreModel(resp.data)
      const payload = {
        data: darkStoreModel.data,
        pagination: darkStoreModel.paginationData
      }
      context.commit(DarkStoresMutations.SET_DARKSTORES, payload)
    }).catch((err: any) => {
      context.commit(DarkStoresMutations.TRIGGER_LOADING, false)
      throw new Error(err)
    })
  },
  [DarkStoresActions.UPDATE_DARKSTORES_SETTINGS]: async (context, { settingsId, payload }) => {
    context.commit(DarkStoresMutations.TRIGGER_LOADING, true)
    return axiosInstance({
      ...apiEndpoints.darkstores.updateSettings(settingsId),
      data: payload
    }).then((resp: any) => {
      toast.success('Darkstore settings has been updated successfully', {
        timeout: 3000
      })
    }).catch((err: any) => {
      throw new Error(err)
    }).finally(() => context.commit(DarkStoresMutations.TRIGGER_LOADING, false))
  }
}
