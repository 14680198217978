import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DriverDeliveryInterface } from '@/shared/interfaces/reports/driver-delivery.interface'
import { formatDate } from '@/shared/utils/formatDate'

export class DriverDeliveryModel extends CommonModel {
  public data: DriverDeliveryInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DriverDeliveryInterface {
    return {
      assignedTime: formatDate(data.assigned_time, 'DD.MM.YYYY HH:mm'),
      createdAt: formatDate(data.created_at, 'DD.MM.YYYY HH:mm'),
      darkstoreName: data.darkstore_name,
      deliveryDuration: data.delivery_duration,
      distance: data.distance,
      driverId: data.driver_id,
      driverName: data.driver_name,
      orderId: data.order_id,
      orderStatus: data.order_status,
      orderTotal: data.order_total,
      readyForPickupTime: formatDate(data.ready_for_pickup_time, 'DD.MM.YYYY HH:mm'),
      shiftEndTime: data.shift_end_time,
      shiftName: data.shift_name,
      shiftStartTime: data.shift_start_time,
      driverReturnDatetime: formatDate(data.driver_return_datetime, 'DD.MM.YYYY HH:mm'),
      driverReturnDuration: data.driver_return_duration,
      locations: { lat: data.driver_return_latitude, long: data.driver_return_longitude }
    }
  }
}
