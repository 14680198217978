import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { Driver } from '@/shared/interfaces/driver.interface'
import { formatDate } from '@/shared/utils/formatDate'

export class DriversModel extends CommonModel {
  public data: Driver[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }
  // lastLogin: formatDate(data.driver_info.last_login, 'dd.MM.YYYY HH:mm'),

  private setData (data: any): Driver {
    return {
      boxNumber: data.box_number,
      cityInfo: {
        id: data.city.id,
        name: data.city.name,
        countryName: data.city.country_name
      },
      createdAt: formatDate(data.created_at, 'DD.MM.YYYY HH:mm'),
      vehicleId: data.vehicle_id,
      darkStoreId: data.darkstore_id,
      isClockIn: data.is_clock_in,
      driverInfo: {
        firstName: data.driver_info.first_name,
        lastName: data.driver_info.last_name,
        userName: data.driver_info.username,
        userPhone: data.driver_info.user_phone_number
      },
      driverAddress: {
        addressBuilding: data.driver_address.address_building,
        addressDistrict: data.driver_address.address_district,
        addressFlat: data.driver_address.address_flat,
        addressFloor: data.driver_address.address_floor,
        addressNearestLandmark: data.driver_address.address_nearest_landmark,
        addressNeighborhood: data.driver_address.address_neighborhood
      },
      driverLicenseNo: data.driver_license_no,
      id: data.id,
      license: data.license,
      driverStatus: {
        id: data.driver_status.id,
        name: data.driver_status.name,
        value: data.driver_status.value
      },
      shift: {
        id: data.shift.id,
        shiftEnd: data.shift.shift_end,
        shiftStart: data.shift.shift_start
      },
      lastLocation: {
        latitude: data?.last_location?.latitude ?? 0,
        longitude: data?.last_location?.longitude ?? 0
      }
    }
  }
}
