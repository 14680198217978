import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { DarkstoreDeliveryMetricsInterface } from '@/shared/interfaces/reports/darkstore-delivery-metrics.interface'
import store from '@/store/index'

const vehicles = () => store.getters['vehicles/getVehicleTypes']
const getVehicleById = (vehicleId:number) => {
  const vehicle = vehicles().filter((v:any) => v.id === vehicleId)
  if (vehicle.length) {
    return vehicle[0].value
  }
  return vehicleId
}

export class DarkstoreDeliveryMetricsModel extends CommonModel {
  public data: DarkstoreDeliveryMetricsInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): DarkstoreDeliveryMetricsInterface {
    return {
      ...data,
      vehicle_type_id: getVehicleById(data.vehicle_type_id)
    }
  }
}
