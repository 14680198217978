import { Method as apiMethod } from 'axios'

export const driverEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/drivers/page'
  },
  listAutocomplete: {
    method: 'get' as apiMethod,
    url: '/drivers/search_autocomplete'
  },
  listLocations: {
    method: 'get' as apiMethod,
    url: '/drivers/clocked_in/locations'
  },
  listMap: {
    method: 'get' as apiMethod,
    url: '/drivers/map/page'
  },
  availableDrivers: {
    method: 'get' as apiMethod,
    url: '/drivers/available_drivers/all'
  },
  singleDriver: {
    method: 'get' as apiMethod,
    url: '/drivers/'
  },
  updateDriver: {
    method: 'put' as apiMethod,
    url: '/drivers/'
  },
  assignVehicle: {
    method: 'post' as apiMethod,
    url: '/drivers/assign_vehicle/'
  },
  assignShift: {
    method: 'put' as apiMethod,
    url: '/drivers/driver_dark_store_and_shift/'
  },
  statuses: {
    method: 'get' as apiMethod,
    url: '/drivers/driver_statuses/all'
  },
  updateStatus: {
    method: 'put' as apiMethod,
    url: '/drivers/update_driver_status_internally/'
  },
  lastLocation: {
    method: 'get' as apiMethod,
    url: '/drivers/last_location/'
  },
  clockInLocation: {
    method: 'get' as apiMethod,
    url: '/drivers/driver_clock_in_loc/'
  },
  clockOutLocation: {
    method: 'get' as apiMethod,
    url: '/drivers/driver_clock_out_loc/'
  },
  currentLocation: {
    method: 'get' as apiMethod,
    url: '/drivers/driver_current_location/'
  },
  forceClockout: {
    method: 'post' as apiMethod,
    url: '/drivers/force_clock_out/'
  },
  tripLocations: {
    method: 'get' as apiMethod,
    url: '/location/locations'
  },
  driverOrders: (driverId:number) => {
    return {
      method: 'get' as apiMethod,
      url: `/drivers/driver_orders/${driverId}`
    }
  },
  forceToDelivery: (driverId:number) => {
    return {
      method: 'post' as apiMethod,
      url: `/drivers/force_start_delivery/${driverId}`
    }
  },
  getLastLocations: {
    method: 'get' as apiMethod,
    url: '/location/last-location'
  }
}
