import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { formatDate } from '@/shared/utils/formatDate'
import { OrdersListInterface } from '@/shared/interfaces/orders-list.interface'

export class OrderModel extends CommonModel {
  public data: OrdersListInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): OrdersListInterface {
    return {
      id: data.id,
      createdAt: data.created_at,
      estimateTime: this.calculateEstimateTime(data),
      customerAddressDetails: data.customer_address_details,
      orderId: data.order_id,
      delayTime: data.delay_time ? Math.ceil(data.delay_time / 60) : null,
      orderStatus: {
        id: data.order_status.id,
        name: data.order_status.name,
        value: data.order_status.value
      },
      orderStatusTime: data?.order_status_time,
      userAddress: data.user_address,
      darkStore: {
        id: data.dark_store.id,
        name: data.dark_store.name
      },
      orderTotal: data.order_total,
      driver: data.driver ? {
        driverInfo: {
          id: data.driver.driver_info.id,
          firstName: data.driver.driver_info.first_name,
          lastName: data.driver.driver_info.last_name,
          userName: data.driver.driver_info.username
        }
      } : null,
      tripDetailsFromDarkstoreOutward: data.trip_details_from_darkstore_outward ? this.setTripOutwardInfo(data.trip_details_from_darkstore_outward) : null,
      tripDetailsToDarkstoreInward: data.trip_details_to_darkstore_inward ? {
        endAddress: data.trip_details_to_darkstore_inward.end_address,
        endLocation: {
          lat: data.trip_details_to_darkstore_inward.end_location.lat,
          lng: data.trip_details_to_darkstore_inward.end_location.lng
        },
        startAddress: data.trip_details_to_darkstore_inward.start_address,
        startLocation: {
          lat: data.trip_details_to_darkstore_inward.start_location.lat,
          lng: data.trip_details_to_darkstore_inward.start_location.lng
        },
        totalTravelDuration: data.trip_details_to_darkstore_inward.total_travel_duration,
        travelDistance: data.trip_details_to_darkstore_inward.travel_distance,
        travelDuration: data.trip_details_to_darkstore_inward.travel_duration,
        overviewPolyline: data.trip_details_to_darkstore_inward.overview_polyline
      } : null,
      priority: data?.priority ?? false,
      traveledDistance: data?.traveled_distance ?? 0
    }
  }

  private calculateEstimateTime (data: any): number {
    let totalDuration = 0
    if (data.trip_details_to_darkstore_inward) {
      totalDuration = data.trip_details_to_darkstore_inward.travel_duration
    }
    if (data.trip_details_from_darkstore_outward) {
      if (data.trip_details_from_darkstore_outward.length) {
        data.trip_details_from_darkstore_outward.forEach((el: any) => {
          totalDuration += el.travel_duration
        })
      } else {
        totalDuration += data.trip_details_from_darkstore_outward.travel_duration
      }
    }
    return Math.floor(totalDuration / 60)
  }

  private setTripOutwardInfo (data: any) {
    if (data.length) {
      return data.map((el: any) => {
        return {
          endAddress: el.end_address,
          endLocation: {
            lat: el.end_location.lat,
            lng: el.end_location.lng
          },
          startAddress: el.start_address,
          startLocation: {
            lat: el.start_location.lat,
            lng: el.start_location.lng
          },
          totalTravelDuration: el.total_travel_duration,
          travelDistance: el.travel_distance,
          travelDuration: el.travel_duration,
          overviewPolyline: el.overview_polyline
        }
      })
    } else {
      return [
        {
          endAddress: data.end_address,
          endLocation: {
            lat: data.end_location.lat,
            lng: data.end_location.lng
          },
          startAddress: data.start_address,
          startLocation: {
            lat: data.start_location.lat,
            lng: data.start_location.lng
          },
          totalTravelDuration: data.total_travel_duration,
          travelDistance: data.travel_distance,
          travelDuration: data.travel_duration,
          overviewPolyline: data.overview_polyline
        }
      ]
    }
  }
}
