import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { ViewOrdersDriversInterface } from '@/shared/interfaces/reports/view-orders-drivers.interface'

export class ViewOrdersDriversModel extends CommonModel {
  public data: ViewOrdersDriversInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): ViewOrdersDriversInterface {
    return {
      assignedStatusDatetime: data.assigned_status_datetime,
      assignedStatusLatitude: data.assigned_status_latitude,
      assignedStatusLongitude: data.assigned_status_longitude,
      atTheAddressStatusDatetime: data.at_the_address_status_datetime,
      atTheAddressStatusLatitude: data.at_the_address_status_latitude,
      atTheAddressStatusLongitude: data.at_the_address_status_longitude,
      canceledStatusDatetime: data.canceled_status_datetime,
      canceledStatusLatitude: data.canceled_status_latitude,
      canceledStatusLongitude: data.canceled_status_longitude,
      customerCode: data.customer_code,
      customerPhoneNumber: data.customer_phone_number,
      darkstore: data.darkstore,
      delayedStatusDatetime: data.delayed_status_datetime,
      delayedStatusLatitude: data.delayed_status_latitude,
      delayedStatusLongitude: data.delayed_status_longitude,
      deliveredStatusDatetime: data.delivered_status_datetime,
      deliveredStatusLatitude: data.delivered_status_latitude,
      deliveredStatusLongitude: data.delivered_status_longitude,
      destinationLatitude: data.destination_latitude,
      destinationLongitude: data.destination_longitude,
      driverId: data.driver_id,
      driverLicenseNo: data.driver_license_no,
      failedDeliveryReasonId: data.failed_delivery_reason_id,
      id: data.is,
      modifiedAt: data.modified_at,
      onTheWayStatusDatetime: data.on_the_way_status_datetime,
      onTheWayStatusLatitude: data.on_the_way_status_latitude,
      onTheWayStatusLongitude: data.on_the_way_status_longitude,
      orderBinInfo: data.order_bin_info,
      orderBinNumber: data.order_bin_number,
      orderCreatedAt: data.order_created_at,
      orderId: data.order_id,
      orderStatusId: data.order_status_id,
      orderStatus: data.order_status,
      orderTotal: data.order_total,
      pickedUpStatusDatetime: data.picked_up_status_datetime,
      pickedUpStatusLatitude: data.picked_up_status_latitude,
      pickedUpStatusLongitude: data.picked_up_status_longitude,
      preparingStatusDatetime: data.preparing_status_datetime,
      preparingStatusLatitude: data.preparing_status_latitude,
      preparingStatusLongitude: data.preparing_status_longitude,
      readyForPickupStatusDatetime: data.ready_for_pickup_status_datetime,
      readyForPickupStatusLatitude: data.ready_for_pickup_status_latitude,
      readyForPickupStatusLongitude: data.ready_for_pickup_status_longitude,
      returnedStatusDatetime: data.returned_status_datetime,
      returnedStatusLatitude: data.returned_status_latitude,
      returnedStatusLongitude: data.returned_status_longitude,
      toReturnAssignedStatusDatetime: data.to_return_assigned_status_datetime,
      toReturnAssignedStatusLatitude: data.to_return_assigned_status_latitude,
      toReturnAssignedStatusLongitude: data.to_return_assigned_status_longitude,
      toReturnStatusDatetime: data.to_return_status_datetime,
      toReturnStatusLatitude: data.to_return_status_latitude,
      toReturnStatusLongitude: data.to_return_status_longitude,
      userAddress: data.user_address,
      username: data.username,
      zoneId: data.zone_id,
      zoneName: data.zone_name
    }
  }
}
