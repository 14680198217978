import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { ReportsState } from '@/store/modules/reports/types'
import { ReportTypesInterface } from '@/shared/interfaces/reports/report-types.interface'
import { ProductivityReportsInterface } from '@/shared/interfaces/reports/productivity-reports.interface'
import { ViewOrdersDriversInterface } from '@/shared/interfaces/reports/view-orders-drivers.interface'
import {
  OrdersByStatusInterface,
  OrdersByStatusMonthlyInterface
} from '@/shared/interfaces/reports/orders-by-status.interface'
import { ProductivityDayInterface } from '@/shared/interfaces/reports/productivity-day.interface'
import { ViewOrdersTimingInterface } from '@/shared/interfaces/reports/view-orders-timing.interface'
import { ViewDriversTimingPerDayInterface } from '@/shared/interfaces/reports/view-drivers-timing-per-day.interface'
import { ViewDriversTimingPerMonthInterface } from '@/shared/interfaces/reports/view-drivers-timing-per-month.interface'
import { DriverActivityInterface } from '@/shared/interfaces/reports/driver-activity.interface'
import { DriverProductivityInterface } from '@/shared/interfaces/reports/driver-productivity.interface'
import { DriverDeliveryInterface } from '@/shared/interfaces/reports/driver-delivery.interface'
import { DarkstoreDeliveryMetricsModel } from '@/shared/models/reports/darkstore-delivery-metrics.model'
import { ZoneDeliveryMetricsInterface } from '@/shared/interfaces/reports/zone-delivery-metrics.interface'
import { DriverPerformanceInterface } from '@/shared/interfaces/reports/driver-performance.interface'
import { DarkstoreOrderPeakHoursInterface } from '@/shared/interfaces/reports/darkstore-order-peak-hours.interface'
import { HourlyDeliveryTimeAnalysisInterface } from '@/shared/interfaces/reports/hourly-delivery-time-analysis.interface'

export const getters: GetterTree<ReportsState, RootState> = {
  getReportTypes (state): ReportTypesInterface[] {
    return state.reportTypes
  },
  getProductivityPagination (state): any {
    return state.productivity.pagination
  },
  getProductivityReports (state): ProductivityReportsInterface[] {
    return state.productivity.reports
  },
  getProductivityExportReports (state): ProductivityReportsInterface[] {
    return state.productivity.exportReports
  },

  getPerformanceReports (state): DriverPerformanceInterface[] {
    return state.driverPerformance.reports
  },
  getPerformanceExportReports (state): DriverPerformanceInterface[] {
    return state.driverPerformance.exportReports
  },
  getPerformancePagination (state): any {
    return state.driverPerformance.pagination
  },

  getProductivityDayPagination (state): any {
    return state.productivityDay.pagination
  },
  getProductivityDayReports (state): ProductivityDayInterface[] {
    return state.productivityDay.reports
  },
  getProductivityDayExportReports (state): ProductivityDayInterface[] {
    return state.productivityDay.exportReports
  },

  getViewOrderDriverPagination (state): any {
    return state.viewOrderDriver.pagination
  },
  getViewOrderDriverReports (state): ViewOrdersDriversInterface[] {
    return state.viewOrderDriver.reports
  },
  getViewOrderDriverExportReports (state): ProductivityReportsInterface[] {
    return state.viewOrderDriver.exportReports
  },

  getOrdersByStatusPagination (state): any {
    return state.ordersByStatus.pagination
  },
  getOrdersByStatusReports (state): OrdersByStatusInterface[] {
    return state.ordersByStatus.reports
  },
  getOrdersByStatusExportReports (state): ProductivityReportsInterface[] {
    return state.ordersByStatus.exportReports
  },

  getOrdersByStatusMonthlyPagination (state): any {
    return state.ordersByStatusMonthly.pagination
  },
  getOrdersByStatusMonthlyReports (state): OrdersByStatusMonthlyInterface[] {
    return state.ordersByStatusMonthly.reports
  },
  getOrdersByStatusMonthlyExportReports (state): any[] {
    return state.ordersByStatusMonthly.exportReports
  },

  getOrdersTimingPagination (state): any {
    return state.ordersTiming.pagination
  },
  getOrdersTimingReports (state): ViewOrdersTimingInterface[] {
    return state.ordersTiming.reports
  },
  getOrdersTimingExportReports (state): ViewOrdersTimingInterface[] {
    return state.ordersTiming.exportReports
  },

  getDriversTimingDailyPagination (state): any {
    return state.driversTimingDaily.pagination
  },
  getDriversTimingDailyReports (state): ViewDriversTimingPerDayInterface[] {
    return state.driversTimingDaily.reports
  },
  getDriversTimingDailyExportReports (state): ViewDriversTimingPerDayInterface[] {
    return state.driversTimingDaily.exportReports
  },

  getDriversTimingMonthlyPagination (state): any {
    return state.driversTimingMonthly.pagination
  },
  getDriversTimingMonthlyReports (state): ViewDriversTimingPerMonthInterface[] {
    return state.driversTimingMonthly.reports
  },
  getDriversTimingMonthlyExportReports (state): ViewDriversTimingPerMonthInterface[] {
    return state.driversTimingMonthly.exportReports
  },

  getDriversActivityPagination (state): any {
    return state.driverActivity.pagination
  },
  getDriversActivityReports (state): DriverActivityInterface[] {
    return state.driverActivity.reports
  },
  getDriversActivityExportReports (state): DriverActivityInterface[] {
    return state.driverActivity.exportReports
  },

  getDriversProductivityPagination (state): any {
    return state.driverProductivity.pagination
  },
  getDriversProductivityReports (state): DriverProductivityInterface[] {
    return state.driverProductivity.reports
  },
  getDriversProductivityExportReports (state): DriverProductivityInterface[] {
    return state.driverProductivity.exportReports
  },

  getDriversDeliveryPagination (state): any {
    return state.driverDelivery.pagination
  },
  getDriversDeliveryReports (state): DriverDeliveryInterface[] {
    return state.driverDelivery.reports
  },
  getDriversDeliveryExportReports (state): DriverDeliveryInterface[] {
    return state.driverDelivery.exportReports
  },

  getDarkStoreDeliveryMetricsPagination (state): any {
    return state.darkStoreDeliveryMetrics.pagination
  },
  getDarkStoreDeliveryMetricsReports (state): DarkstoreDeliveryMetricsModel[] {
    return state.darkStoreDeliveryMetrics.reports
  },
  getDarkStoreDeliveryMetricExportReports (state): any[] {
    return state.darkStoreDeliveryMetrics.exportReports as any
  },

  getZoneDeliveryMetricsPagination (state): any {
    return state.zoneDeliveryMetrics.pagination
  },
  getZoneDeliveryMetricsReports (state): ZoneDeliveryMetricsInterface[] {
    return state.zoneDeliveryMetrics.reports
  },
  getZoneDeliveryMetricExportReports (state): any[] {
    return state.zoneDeliveryMetrics.exportReports as any
  },

  getDarkstorePeakHoursReports (state): DarkstoreOrderPeakHoursInterface[] {
    return state.darkStoreOrderPeakHours.reports
  },
  getDarkstorePeakHoursExportReports (state): any[] {
    return state.darkStoreOrderPeakHours.exportReports as any
  },

  getHourlyDeliveryTimeAnalysisReports (state): HourlyDeliveryTimeAnalysisInterface[] {
    return state.hourlyDeliveryTimeAnalysis.reports
  },
  getHourlyDeliveryTimeAnalysisExportReports (state): any[] {
    return state.hourlyDeliveryTimeAnalysis.exportReports as any
  },

  getExportLoading (state): boolean {
    return state.exportLoading
  },
  getLoading (state): boolean {
    return state.loading
  }
}
