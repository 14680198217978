import { Method as apiMethod } from 'axios'

export const darkstoreEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/dark_stores/page'
  },
  updateSettings (settingsId: number) {
    return {
      method: 'put' as apiMethod,
      url: `/darkstore_settings/${settingsId}`
    }
  }
}
